<template>
  <div style="padding: 100px">
    <el-input
      ref="saveTagInput"
      v-model="contract"
      placeholder="合约地址"
      class="input-new-tag"
      size="small"
      style="
        margin-right: 10px;
        margin-top: 10px;
        maxwidth: 100px;
        margin-left: 0px;
      "
    />

    <el-input
      ref="saveTagInput"
      v-model="inputValue"
      placeholder="input"
      class="input-new-tag"
      size="small"
      style="
        margin-right: 10px;
        margin-top: 10px;
        maxwidth: 100px;
        margin-left: 0px;
      "
    />

    <p>Reuslt: {{ message }}</p>

    <el-button
      size="mini"
      type="primary"
      @click="confirmAction"
      v-loading="actionLoading"
      >Upload</el-button
    >
  </div>
</template>
<script>
import NCWeb3 from "../assets/js/web3.js";

export default {
  name: "RPC",
  data() {
    return {
      actionLoading: false,
      inputValue: "",
      message: "",
      contract: "",
    };
  },
  mounted() {},
  methods: {
    confirmAction() {
      this.actionLoading = true;
      NCWeb3.sendWithInput(
        (result) => {
          this.actionLoading = false;
          this.message = result;
        },
        (err) => {
          this.actionLoading = false;
          this.message = err;
        },
        this.contract,
        this.inputValue,
        0
      );
    },
  },
};
</script>
